@import '~normalize.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');
@font-face {
  font-family: 'aileronregular';
  src: url('../../fonts/Aileron-Regular-webfont.eot');
  src: url('../../fonts/Aileron-Regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('../../fonts/Aileron-Regular-webfont.woff') format('woff'),
       url('../../fonts/Aileron-Regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
